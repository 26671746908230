<template>
  <div class="buildings">
    <v-data-table
      :headers="headers"
      :items="buildings"
      hide-default-footer
      disable-pagination
      :no-data-text="noData"
      :search="search"
      class="elevation-1"
      show-expand
      single-expand
    >
      <template v-slot:top>
        <v-toolbar elevation="1" height="100">
          <v-dialog v-model="dialog" max-width="1200" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-container>
                <v-spacer></v-spacer>
                <div class="text-center">
                  <v-btn color="success" rounded v-bind="attrs" v-on="on">
                    <v-icon>fa-plus</v-icon>
                  </v-btn>
                  <div class="caption">Agregar Cliente</div>
                </div>
                <v-spacer></v-spacer>
              </v-container>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-card min-height="382">
                          <v-card-title>Identificacion</v-card-title>
                          <v-card-text>
                            <v-text-field
                              v-if="editedIndex === -1"
                              v-model="editedItem.id"
                              label="ID (IMEI o Virtual)"
                              :rules="requiredRuleName"
                              @input="inputChanged"
                              required
                              append-outer-icon="mdi-auto-fix"
                              @click:append-outer="generateID"
                              counter
                              maxlength="20"
                            ></v-text-field>
                            <v-text-field
                              v-if="editedIndex >= 0"
                              v-model="editedItem.id"
                              label="ID (IMEI o Virtual)"
                              readonly
                              disabled
                              counter
                              maxlength="20"
                            ></v-text-field>

                            <v-text-field
                              v-model="editedItem.name"
                              label="Nombre comercial (cliente / edificio / barrio)"
                              :rules="requiredRuleName"
                              @input="inputChanged"
                              required
                              counter="25"
                              maxlength="25"
                            ></v-text-field>

                            <v-text-field
                              v-model="editedItem.address"
                              label="Direccion"
                              :rules="requiredRuleName"
                              @input="inputChanged"
                              required
                              counter="25"
                              maxlength="25"
                            ></v-text-field>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="3">
                        <v-card min-height="382">
                          <v-card-title>Licencia</v-card-title>
                          <v-card-text>
                            <v-checkbox
                              v-model="editedItem.gate"
                              label="Gate"
                              @change="inputChanged"
                            ></v-checkbox>
                            <v-checkbox
                              v-model="editedItem.virtual"
                              label="Virtual QR"
                              @change="inputChanged"
                            ></v-checkbox>
                            <v-checkbox
                              v-model="editedItem.lite"
                              label="Lite"
                              @change="inputChanged"
                            ></v-checkbox>
                            <v-text-field
                              v-model="editedItem.units"
                              label="Unidades funcionales"
                              @input="inputChanged"
                              :rules="requiredRuleNumber1"
                              required
                              type="number"
                            ></v-text-field>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="3">
                        <v-card class="mb-4">
                          <v-card-title>Estado</v-card-title>
                          <v-card-text>
                            <v-select
                              v-model="editedItem.status"
                              :items="statuses"
                              item-text="name"
                              item-value="value"
                              label="Estado"
                              @input="inputChanged"
                              required
                            ></v-select>
                          </v-card-text>
                        </v-card>
                        <v-card>
                          <v-card-title>Accesorios</v-card-title>
                          <v-card-text>
                            <v-text-field
                              v-model="editedItem.accesses"
                              label="Accesos"
                              @input="inputChanged"
                              :rules="requiredRuleNumber0"
                              required
                              type="number"
                            ></v-text-field>
                            <v-checkbox
                              v-model="editedItem.audio"
                              label="Audio Booster"
                              @change="inputChanged"
                            ></v-checkbox>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-card>
                          <v-card-title
                            >Credenciales de Connectycube</v-card-title
                          >
                          <v-card-text>
                            <v-row>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.videoappid"
                                  label="App ID"
                                  :rules="requiredRuleName"
                                  @input="inputChanged"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.videoauthkey"
                                  label="Auth Key"
                                  :rules="requiredRuleName"
                                  @input="inputChanged"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.videoauthsecret"
                                  label="Auth Secret"
                                  :rules="requiredRuleName"
                                  @input="inputChanged"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-card>
                          <v-card-title
                            >Credenciales de usuario de Video</v-card-title
                          >
                          <v-card-text>
                            <v-row>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.videologin"
                                  label="Login"
                                  :rules="requiredRuleName"
                                  @input="inputChanged"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.videouser"
                                  label="User"
                                  :rules="requiredRuleName"
                                  @input="inputChanged"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.videopass"
                                  label="Password"
                                  :rules="requiredRuleName"
                                  @input="inputChanged"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                  :disabled="loading"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="save"
                  :loading="loading"
                  :disabled="!isValid"
                >
                  Guardar
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-card-title>
          Clientes
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div>
            <strong>Credenciales de Connectycube :</strong><br />
            App ID: {{ item.videoappid }}<br />
            Auth Key: {{ item.videoauthkey }}<br />
            Auth Secret: {{ item.videoauthsecret }}<br />
          </div>
          <div>
            <strong>Credenciales de usuario de Video</strong><br />
            Login: {{ item.videologin }}<br />
            User: {{ item.videouser }}<br />
            Pass: {{ item.videopass }}<br />
          </div>
        </td>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip v-if="item.status === 'active'" color="green" dark>
          Activo
        </v-chip>
        <v-chip v-if="item.status === 'inactive'" color="red" dark>
          Inactivo
        </v-chip>
        <v-chip v-if="item.status === 'owe'" color="orange" dark>
          Adeuda
        </v-chip>
        <v-chip v-if="item.status === 'N/A'" color="gray" dark>
          No informado
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon></template
          >
          <span>Editar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { db } from "../../fb";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    loading: false,
    buildings: [],
    licenses: [],
    snackbar: {
      visible: false,
      timeout: 2000,
      text: "",
      color: "",
    },
    dialog: false,
    search: "",
    headers: [
      { text: "Estado", align: "start", value: "status" },
      { text: "ID", align: "start", value: "id" },
      { text: "Nombre", value: "name" },
      { text: "Direccion", value: "address" },
      { text: "Licencia", value: "license_text" },
      { text: "Unidades", value: "units" },
      { text: "Accesos", value: "accesses" },
      { text: "Mas", value: "data-table-expand" },
      { text: "Acciones", value: "actions", align: "end" },
    ],
    statuses: [
      { name: "Activo", value: "active" },
      { name: "Inactivo", value: "inactive" },
      { name: "Adeuda", value: "owe" },
      { name: "No informado", value: "N/A" },
    ],
    editedIndex: -1,
    editedItem: {
      status: "active",
      id: "",
      name: "",
      address: "",
      gate: false,
      virtual: true,
      lite: true,
      units: 1,
      accesses: 0,
      audio: false,
      videologin: "3h0xSVHbBmb8GsD40gP8",
      videouser: "5122133",
      videopass: "y92ujrebhk1",
      videoappid: "5642",
      videoauthkey: "AR9J7XxNgxb7xJM",
      videoauthsecret: "RNY5TNNymnjKHhL",
    },
    defaultItem: {
      status: "active",
      id: "",
      name: "",
      address: "",
      gate: false,
      virtual: true,
      lite: true,
      units: 1,
      accesses: 0,
      audio: false,
      videologin: "3h0xSVHbBmb8GsD40gP8",
      videouser: "5122133",
      videopass: "y92ujrebhk1",
      videoappid: "5642",
      videoauthkey: "AR9J7XxNgxb7xJM",
      videoauthsecret: "RNY5TNNymnjKHhL",
    },
    noData: "Buscando clientes ...",
    requiredRuleName: [(v) => !!v || "El valor es requerido"],
    requiredRuleNumber0: [(v) => v >= 0 || "El valor no puede ser negativo"],
    requiredRuleNumber1: [(v) => v >= 1 || "El valor debe ser mayor que 0"],
    isValid: false,
  }),

  mounted() {
    this.snackbar.visible = true;
    this.snackbar.text = "Buscando clientes ...";
    this.snackbar.color = "success";
    this.getBuildings();
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo cliente" : "Editar cliente";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    generateID() {
      const length = 20;
      let id = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        id += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      this.editedItem.id = id;
    },

    async getBuildings() {
      try {
        await db
          .collection("licenses")
          .get()
          .then((licenses) => {
            this.licenses = [];
            licenses.forEach((license) => {
              db.doc("licenses/" + license.id + "/video/" + license.id)
                .get()
                .then((video) => {
                  let data = {
                    status: license.data().status ?? "N/A",
                    id: license.id,
                    audio: license.data().audio,
                    gate: license.data().gate,
                    doors: license.data().doors ?? 0,
                    virtual: license.data().virtual,
                    lite: license.data().lite ?? false,
                    units: license.data().units,
                    videoappid: license.data().video.params.APP_ID,
                    videoauthkey: license.data().video.params.AUTH_KEY,
                    videoauthsecret: license.data().video.params.AUTH_SECRET,
                    videologin: video.data().login,
                    videouser: video.data().user,
                    videopass: video.data().pass,
                  };
                  this.licenses.push(data);
                });
            });
            db.collection("buildings")
              .get()
              .then((buildings) => {
                this.buildings = [];
                buildings.forEach((building) => {
                  let l = this.licenses.find((l) => l.id === building.id);
                  let licenseText = "";
                  if (l.gate) {
                    licenseText = licenseText + "Gate";
                  }
                  if (l.virtual) {
                    licenseText = licenseText + " Virtual";
                  }
                  if (l.lite) {
                    licenseText = licenseText + " Lite";
                  }
                  let data = {
                    status: l.status,
                    id: building.id,
                    name: building.data().name,
                    address: building.data().address,
                    license_text: licenseText,
                    audio: l.audio,
                    gate: l.gate,
                    virtual: l.virtual,
                    lite: l.lite,
                    units: l.units,
                    accesses: l.doors ?? 0,
                    videoappid: l.videoappid,
                    videoauthkey: l.videoauthkey,
                    videoauthsecret: l.videoauthsecret,
                    videologin: l.videologin,
                    videouser: l.videouser,
                    videopass: l.videopass,
                  };
                  this.buildings.push(data);
                });
              });
          });
      } catch (error) {
        console.log(error);
      }
    },

    inputChanged() {
      if (
        this.$refs.form.validate() &&
        (this.editedItem.gate || this.editedItem.virtual || this.editedItem.lite)
      ) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    },

    editItem(item) {
      this.isValid = false;
      this.editedIndex = this.buildings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.buildings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.isValid = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      // Edicion
      if (this.editedIndex > -1) {
        this.loading = true;
        this.snackbar.visible = true;
        this.snackbar.text = "Guardados cambios...";
        this.snackbar.color = "success";
        let licenseData = {
          audio: this.editedItem.audio,
          doors: parseInt(this.editedItem.accesses),
          gate: this.editedItem.gate,
          virtual: this.editedItem.virtual,
          lite: this.editedItem.lite,
          status: this.editedItem.status,
          units: parseInt(this.editedItem.units),
          video: {
            params: {
              APP_ID: this.editedItem.videoappid,
              AUTH_KEY: this.editedItem.videoauthkey,
              AUTH_SECRET: this.editedItem.videoauthsecret,
            },
            provider: 'connectycube',
          },
        };
        // Update license.
        db.doc("licenses/" + this.editedItem.id)
          .set(licenseData, {merge: true})
          .then(() => {
            // Update video license data.
            let videoLicenseData = {
              login: this.editedItem.videologin,
              user: this.editedItem.videouser,
              pass: this.editedItem.videopass,
            };
            db.doc(
              "licenses/" + this.editedItem.id + "/video/" + this.editedItem.id
            )
              .update(videoLicenseData)
              .then(() => {
                // Update Building.
                let buildingData = {
                  address: this.editedItem.address,
                  name: this.editedItem.name,
                };
                db.doc("buildings/" + this.editedItem.id)
                  .update(buildingData)
                  .then(() => {
                    let licenseText = "";
                    if (this.editedItem.gate) {
                      licenseText = licenseText + "Gate";
                    }
                    if (this.editedItem.virtual) {
                      licenseText = licenseText + " Virtual";
                    }
                    if (this.editedItem.lite) {
                      licenseText = licenseText + " Lite";
                    }
                    this.editedItem.license_text = licenseText                    
                    this.loading = false;
                    Object.assign(
                      this.buildings[this.editedIndex],
                      this.editedItem
                    );
                    Object.assign(
                      this.buildings[this.editedIndex],
                      this.editedItem
                    );
                    this.close();
                  });
              });
          });
      } else {
        // Creacion
        this.loading = true;
        this.snackbar.visible = true;
        this.snackbar.text = "Agregado cliente ...";
        this.snackbar.color = "success";

        // Create Licenses.
        let licenseData = {
          audio: this.editedItem.audio,
          demo: false,
          doors: parseInt(this.editedItem.accesses),
          gate: this.editedItem.gate,
          virtual: this.editedItem.virtual,
          lite: this.editedItem.lite,
          status: this.editedItem.status,
          rfid: false,
          units: parseInt(this.editedItem.units),
          video: {
            params: {
              APP_ID: this.editedItem.videoappid,
              AUTH_KEY: this.editedItem.videoauthkey,
              AUTH_SECRET: this.editedItem.videoauthsecret,
            },
            provider: "connectycube",
          },
        };
        db.collection("licenses/")
          .doc(this.editedItem.id)
          .set(licenseData)
          .then(() => {
            // Create video collection license.
            let videoLicenseData = {
              login: this.editedItem.videologin,
              user: this.editedItem.videouser,
              pass: this.editedItem.videopass,
            };
            db.collection("licenses/" + this.editedItem.id + "/video/")
              .doc(this.editedItem.id)
              .set(videoLicenseData)
              .then(() => {
                // Create Building.
                let buildingData = {
                  address: this.editedItem.address,
                  alpha: false,
                  camera_psoitions: [],
                  created: new Date(),
                  floor_positions: [],
                  location: {
                    lat: 0,
                    lng: 0,
                    range: 0,
                  },
                  name: this.editedItem.name,
                  security_positions: [],
                  settings: {
                    call_time: 120,
                    call_repetitions: 2,
                    mode: true,
                    mode_sort: true,
                  },
                  total_units: 0,
                  vid: this.editedItem.id,
                };
                db.collection("buildings/")
                  .doc(this.editedItem.id)
                  .set(buildingData)
                  .then(() => {
                    this.loading = false;
                    // Update datatable.
                    let licenseText = "";
                    if (this.editedItem.gate) {
                      licenseText = licenseText + "Gate";
                    }
                    if (this.editedItem.virtual) {
                      licenseText = licenseText + " Virtual";
                    }
                    if (this.editedItem.lite) {
                      licenseText = licenseText + " Lite";
                    }
                    let data = {
                      status: this.editedItem.status,
                      id: this.editedItem.id,
                      name: this.editedItem.name,
                      address: this.editedItem.address,
                      license_text: licenseText,
                      gate: this.editedItem.gate,
                      virtual: this.editedItem.virtual,
                      lite: this.editedItem.lite,
                      units: this.editedItem.units,
                      accesses: this.editedItem.accesses ?? 0,
                      videoappid: this.editedItem.videoappid,
                      videoauthkey: this.editedItem.videoauthkey,
                      videoauthsecret: this.editedItem.videoauthsecret,
                      videologin: this.editedItem.videologin,
                      videouser: this.editedItem.videouser,
                      videopass: this.editedItem.videopass,
                    };
                    this.buildings.push(data);
                    this.close();
                  });
              });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sortHandler {
  &:hover {
    cursor: ns-resize;
  }
}
</style>
