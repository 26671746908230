<template>
  <div class=''>
    <v-form ref="form" @submit.prevent="getUserByEmail()">
      <v-container>

        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="email"
              label="E-mail"
              type="email"
              filled
              dense
              required
              clearable
              clear-icon="mdi-close-circle"
              :append-outer-icon="isValid ? 'mdi-magnify' : ''"
              @click:append-outer="getUserByEmail()"
              @click:clear="clearEmail()"
              :rules="emailRules"
              @input="inputChanged"
              :loading="loading"
              :disabled="loading"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-card
          v-if="editUser.uid"
          class="mx-auto"
        >
          <v-card-title class="">
            {{this.editUser.displayName}}
            <v-card-subtitle class="">
              <v-icon>mdi-identifier</v-icon>
              {{editUser.uid}}
            </v-card-subtitle>
          </v-card-title>

          <v-card-subtitle class="pt-8 pb-0">
            Usuario
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="4">
              <v-card-text>
                <v-icon>mdi-email</v-icon>
                {{this.email}}
                <v-chip
                  class="ml-4"
                  filter
                  filter-icon="mdi-plus"
                  :color="editUser.emailVerified ? 'green' : 'red'"
                  outlined
                >
                {{this.editUser.emailVerified ? 'Verificado' : 'No Verificado'}}
                </v-chip>
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card-text>
                <v-icon>mdi-phone</v-icon>
                {{this.editUser.phoneNumber}}
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card-text>
                <v-icon>mdi-account-key</v-icon>
                {{editUser.customClaims.role}}
              </v-card-text>
            </v-col>
          </v-row>

          <v-card-subtitle class="pt-8 pb-0">
            Contacto
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="3">
              <v-card-text>
                <v-icon>mdi-domain</v-icon>
                {{editUser.customClaims.building}}
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card-text>
                <v-icon>mdi-database</v-icon>
                {{editUser.customClaims.floor}}
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card-text>
                <v-icon>mdi-grid</v-icon>
                {{editUser.customClaims.apartment}}
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card-text>
                <v-icon>mdi-account</v-icon>
                {{editUser.customClaims.contact}}
              </v-card-text>
            </v-col>
          </v-row>

          <v-card-subtitle class="pt-8 pb-0">
            Permisos
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              <v-card-text>
                <v-select
                  :items="buildings"
                  :item-text="getFieldText"
                  multiple
                  chips
                  v-model="editUser.buildings"
                  return-object
                  label="Edificios"
                >
                </v-select>
              </v-card-text>
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12">
              <v-btn @click="saveUser">Guardar</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-form>
    <div>{{editUser}}</div>

    <v-snackbar
      v-model='snackbar.visible'
      :timeout='snackbar.timeout'
      :color='snackbar.color'
    >
      {{ snackbar.text }}
    </v-snackbar>

  </div>
  
</template>

<script>
import { db } from '../../fb'
import { ff } from '../../fb'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    email: 'ariel.kanelson@gmail.com',
    editUser: {
      uid: null,
      email: null,
      phoneNumber: null,
      displayName: null,
      disabled: false,
      emailVerified: null,
      customClaims: null,
      buildings: [],
    },
    buildings: [],
    loading: false,
    snackbar: {
      visible: false,
      timeout: 3000,
      text: '',
      color: '',
    },
     emailRules: [ v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail invalido' ],
     isValid: false,
  }),

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  mounted() {
    this.getBuildings()
  },

  methods: {

    getFieldText (item)
    {
      return `${item.id} - ${item.name} - ${item.address}`
    },

    clearEmail () {
      this.email = ''
      this.isValid = false
      this.editUser.uid = null
    },

    inputChanged () {
      if (this.$refs.form.validate() && this.email.length) {
        this.isValid = true
      } else {
        this.isValid = false
      }
    },

    async getBuildings() {
      try {
        await db
          .collection("buildings/")
          .get()
          .then((buildings) => {
            this.buildings = [];
            buildings.forEach((building) => {
              this.buildings.push({ name: building.data().name, address: building.data().address, id: building.id });
            });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getUserByEmail () {
      this.editUser.uid = null
      
      if (!this.$refs.form.validate() || !this.email.length) {
        return
      }
      
      let data = {email: this.email}
      this.loading = true
      ff.httpsCallable('getUserByEmail')(data)
        .then((result) => {
        if (!result.data.error) {
          this.editUser.uid = result.data.uid
          this.editUser.email = result.data.email
          this.editUser.phoneNumber = result.data.phoneNumber
          this.editUser.displayName = result.data.displayName
          this.editUser.disabled = result.data.disabled
          this.editUser.emailVerified = result.data.emailVerified
          this.editUser.customClaims = result.data.customClaims
        } else {
          this.snackbar.visible = true
          this.snackbar.text = result.data.error.code + ' - ' + result.data.error.message
          this.snackbar.color = 'error'
          console.log('error', result.data.error.code + ' - ' + result.data.error.message)
        }
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
      })
    },

    async saveUser() {
      let buildings = [];
      this.editUser.buildings.forEach(building => {
        buildings.push(building.id)
      });
      let data = {
        uid: this.editUser.uid,
        email: this.editUser.email,
        phoneNumber: this.editUser.phoneNumber,
        emailVerified: this.editUser.emailVerified,
        displayName: this.editUser.displayName,
        photoURL: null,
        disabled: this.editUser.disabled,
        buildings: buildings
      }

      this.loading = true
      ff.httpsCallable('saveUser')(data)
        .then((result) => {
        if (!result.data.error) {
          console.log(result.data)
        } else {
          this.snackbar.visible = true
          this.snackbar.text = result.data.error.code + ' - ' + result.data.error.message
          this.snackbar.color = 'error'
          console.log('error', result.data.error.code + ' - ' + result.data.error.message)
        }
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
      })

    }
  }
}
</script>
