<template>
  <div class='userser'>
    <v-data-table
      :headers="headers"
      :items="all_users"
      :options.sync="options"
      :server-items-length="totalUsers"
      :loading="loading"
      loading-text="Buscando usuarios ..."
      :no-data-text="noData"
      class="elevation-1"
      show-expand
      single-expand
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar elevation="1" height="150">
          <v-dialog v-model="dialog" max-width="500px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-container>
                <v-spacer></v-spacer>
                <div class="text-center mb-5">
                  <v-btn
                    color="success"
                    rounded
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!user"
                  >
                    <v-icon>fa-plus</v-icon>
                  </v-btn>
                  <div class="caption">Agregar Usuario</div>
                </div>
                <v-spacer></v-spacer>
              </v-container>
            </template>

            <v-form ref="form">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.displayName"
                          label="Nombre"
                          :rules="requiredRuleName"
                          @input="inputChanged"
                          required
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.email"
                          label="Email"
                          @input="inputChanged"
                          :rules="requiredRule"
                          required
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.phone"
                          label="Teléfono"
                          @input="inputChanged"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="editedIndex == -1">
                      <v-col cols="6">
                        <v-text-field
                          v-model="editedItem.password"
                          label="Clave"
                          @input="inputChanged"
                          :rules="requiredRule"
                          required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editedItem.password2"
                          label="Repetir Clave"
                          @input="inputChanged"
                          :rules="requiredRule"
                          required
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                    :disabled="loading"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click.prevent="submit"
                    :disabled="!isValid"
                    :loading="loading"
                  >
                    Guardar
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>

        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div v-if="item.customClaims.buildings">Administrador en: {{ item.customClaims.buildings }}</div>
          <div>Edificio: {{ item.customClaims.building }}</div>
          <div>Planta: {{ item.customClaims.floor }}</div>
          <div>Unidad: {{ item.customClaims.apartment }}</div>
          <div>Contacto: {{ item.customClaims.contact }}</div>
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon dense class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon dense class="mr-2" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
        <v-icon dense class="mr-2" @click="sendEmail(item)" v-if="item.email">
          {{ item.email_icon }}
        </v-icon>
        <v-icon dense class="mr-2" v-if="!item.email"> mdi-email-off </v-icon>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-btn
        color="primary"
        class="mr-2"
        @click="getAllUsers"
      >
        Cargar mas
      </v-btn>
    </div>
    <v-snackbar
      v-model='snackbar.visible'
      :timeout='snackbar.timeout'
      :color='snackbar.color'
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { ff } from '../../fb'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    options: {},
    totalUsers: 0,
    usr: null,
    all_users: [],
    loading: false,
    snackbar: {
      visible: false,
      timeout: 2000,
      text: '',
      color: '',
    },
    headers: [
      { text: "Nombre", align: "start", sortable: false, value: "displayName" },
      { text: "Email", align: "start", sortable: false, value: "email" },
      { text: "Rol", align: "start", sortable: false, value: "role" },
      { text: "Más", value: "data-table-expand", sortable: false },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
    ],
    dialog: false,
    editedIndex: -1,
    editedItem: {
      id: '',
      customClaims: '',
      displayName: '',
      email: '',
      creationTime: '',
      role: '',
      phone: '',
      emailVerified: false,
      disabled: false,
      metadata: null,
    },
    beforeEditedItem: {
      id: '',
      customClaims: '',
      displayName: '',
      email: '',
      creationTime: '',
      role: '',
      phone: '',
      emailVerified: false,
      disabled: false,
      metadata: null,
    },
    defaultItem: {
      id: '',
      customClaims: '',
      displayName: '',
      email: '',
      creationTime: '',
      role: '',
      phone: '',
      emailVerified: false,
      disabled: false,
      metadata: null,
    },
    isValid: false,
    noData: "No se encontraron usuarios.",
    pageToken: "1",
    requiredRuleName: [(v) => !!v || "El Nombre es requerido"],
    requiredRule: [(v) => !!v || "Campo requerido"],

  }),
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Usuario" : "Editar Usuario";
    },
  },

  mounted() {
      //this.getAllUsers(2)
  },
  
  watch: {
    options: {
      handler () {
        this.getAllUsers()
      },
      deep: true,
    },
  },

  methods: {
    async getAllUsers () {
      let data = {page: this.pageToken}
      this.loading = true
      ff.httpsCallable('getAllUsers')(data)
        .then((result) => {
          if (!result.data.error) {
            console.log(result.data)
            this.pageToken = result.data.pageToken
            result.data.users.forEach((user) => {
                const usr = {
                    id: user.uid,
                    customClaims: user.customClaims,
                    displayName: user.displayName,
                    email: user.email,
                    creationTime: user.creationTime,
                    role: user.customClaims.role,
                    phone: user.phoneNumber,
                    emailVerified: user.emailVerified,
                    disabled: user.disabled,
                    metadata: user.metadata,
                }
                this.all_users.push(usr)
                this.totalUsers = this.all_users.length
            });
          } else {
            this.snackbar.visible = true
            this.snackbar.text = result.data.error.code + ' - ' + result.data.error.message
            this.snackbar.color = 'error'
            console.log('error', result.data.error.code + ' - ' + result.data.error.message)
          }
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },

    inputChanged() {
      if (this.$refs.form.validate()) {
        this.isValid = true;
    } else {
        this.isValid = false;
      }
    },

    editItem(item) {
      this.isValid = false;
      this.editedIndex = this.all_users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.isValid = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  }
}
</script>